import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';
class ClientAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_name: '',
      user_type: '',
      users: [],
      portfolios: [],
      client_id: 'All',
      portfolio_id: 'All'
    };
    this.handlePortfolioChange = this.handlePortfolioChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePortfolioChange(event) {
    const portfolio_id = event.target.value;
    this.setState({ portfolio_id });
  }

  async handleUserChange(event) {
    Toast.loading('In Progress', () => { });

    const client_id = event.target.value.split(' | ')[0];

    await fetchAuthSession().then((user) => {
      const config = {};
      config.params = { user_id: client_id };
      config.headers = {
        'Authorization': cookies.get('Authorization'),
        'User-Specified': user.tokens.accessToken.payload.username,
        'Type-Specified': user.tokens.accessToken.payload['cognito:groups']
      };
      axios
        .get(`${base_url}/portfolio-user-mapping`, config)
        .then((res) => {
          this.setState({
            client_id,
            portfolios: [{ portfolio_id: 'All' }].concat(
              res.data.items.length > 0 ? res.data.items[0].portfolios : []
            ),
            portfolio_id: 'All',
            user_name: user.tokens.accessToken.payload.username,
            user_type: user.tokens.accessToken.payload['cognito:groups']
          });
          Toast.success('Success', 1000, () => { });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    this.setState({ client_id });
  }

  async handleSubmit() {
    if (this.state.client_id !== 'All' && this.state.portfolio_id !== 'All') {
      cookies.set('User-Specified', this.state.client_id, { path: '/' });
      cookies.set('Portfolio-Specified', this.state.portfolio_id, {
        path: '/'
      });
      cookies.set('Type-Specified', 'Client', { path: '/' });
    } else if (this.state.client_id !== 'All' && this.state.portfolio_id === 'All') {
      cookies.set('User-Specified', this.state.client_id, { path: '/' });
      cookies.set('Type-Specified', 'Client', { path: '/' });
      cookies.remove('Portfolio-Specified', { path: '/' });
    } else {
      await fetchAuthSession().then((user) => {
        cookies.set('User-Specified', user.tokens.accessToken.payload.username, {
          path: '/'
        });
        cookies.set('Type-Specified', user.tokens.accessToken.payload['cognito:groups'], {
          path: '/'
        });
        cookies.remove('Portfolio-Specified', { path: '/' });
      });
    }
    window.location.reload();
  }

  async componentDidMount() {
    Toast.loading('In Progress', () => {});

    await fetchAuthSession().then((user) => {
      let config = {};
      config.params = { user_type: 'Client' };
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': user.tokens.accessToken.payload.username,
        'Type-Specified': user.tokens.accessToken.payload['cognito:groups']
      };
      axios
        .get(`${base_url}/user`, config)
        .then((res) => {
          this.setState({
            users: [{ user_id: 'All' }].concat(res.data.items),
            user_name: user.tokens.accessToken.payload.username,
            user_type: user.tokens.accessToken.payload['cognito:groups'],
            client_id:
              cookies.get('User-Specified') != null ? cookies.get('User-Specified') : 'All',
            portfolio_id:
              cookies.get('Portfolio-Specified') != null
                ? cookies.get('Portfolio-Specified')
                : 'All'
          });
          Toast.success('Success', 1000, () => {});
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });

      if (cookies.get('User-Specified') == null) {
        return;
      }

      config = {};
      const client_id = cookies.get('User-Specified');
      config.params = { user_id: client_id };
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/portfolio-user-mapping`, config)
        .then((res) => {
          this.setState({
            client_id,
            portfolios: [{ portfolio_id: 'All' }].concat(
              res.data.items.length > 0 ? res.data.items[0].portfolios : []
            ),
            portfolio_id:
              cookies.get('Portfolio-Specified') != null
                ? cookies.get('Portfolio-Specified')
                : 'All',
            user_name: cookies.get('User-Specified'),
            user_type: cookies.get('Type-Specified')
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="input-group mb-3" id="portfolioData">
          {this.state.user_type !== 'Client' && (
            <>
              <select
                className="form-control"
                onChange={this.handleUserChange}
                value={this.state.client_id}>
                {this.state.users.map((value, index) => (
                  <option
                    key={value.user_id}
                    value={value.user_id}
                    selected={value.user_id === this.state.client_id}>
                    {value.user_id}
                    {value.name && ' | ' + value.name}
                  </option>
                ))}
              </select>
              <select
                className="form-control"
                onChange={this.handlePortfolioChange}
                value={this.state.portfolio_id}>
                {this.state.portfolios.map((value, index) => {
                  return (
                    <option
                      key={value.portfolio_id}
                      value={value.portfolio_id}
                      selected={value.portfolio_id === this.state.portfolio_id}>
                      {value.portfolio_id}
                    </option>
                  );
                })}
              </select>
              <br></br>
              <button className="btn btn-primary" onClick={this.handleSubmit}>
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ClientAdd;
