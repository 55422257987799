import { fetchAuthSession } from 'aws-amplify/auth';
import React, { Component } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Toast from 'light-toast';
import { FaCloud, FaCog, FaFilter, FaLayerGroup, FaTrash } from 'react-icons/fa';
import './styles.css';

import StructurePopover from './structurePopover';

import cookies from '../utils/cookies.util';
import base_url, { bucket_name, region } from '../utils/request.util';

const column_names = {
  // "Barrier": "Barrier",
  // "Strike": "Strike",

  Code: 'code',
  Name: 'name',
  Sector: 'sector',
  Industry: 'industry',
  'Initial Price': 'initial_reference_price',
  'Last Price': 'last_price',

  'Basket Price Latest': 'basket_price_latest',

  'Barrier Price': 'barrier_level',
  'Strike Price': 'strike_level',
  'Coupon Level': 'coupon_level',
  'Autocall Level': 'autocall_level',
  'Next Autocall Level': 'next_autocall_level',
  'Next Autocall %': 'autocall_percentage',

  'Maturity Date': 'date_determination',
  'First Autocall Date': 'first_autocall_date',
  'Next Autocall Date': 'next_autocall_date',
  'Initial Fixing Date': 'initial_fixing_date',

  '% Change': 'percentage_change',
  'Distance To Autocall': 'percentage_distance_from_autocall',
  'Distance To Barrier': 'percentage_distance_from_barrier',
  'Distance To Coupon': 'percentage_distance_from_coupon',
  'Distance To Strike': 'percentage_distance_from_strike',

  Status: 'status',
  Type: 'type'
};

function getCodes(underlying) {
  const codesList = [];
  underlying.forEach((element) => {
    codesList.push(element.code.split(' ')[0]);
  });
  return codesList.join('-');
}

class AutocallableStructures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: '',
      columns: [],
      underlying_count: {},
      holding_count: {},
      product_details: [],
      show_basic: window.location.pathname !== '/structure/autocallable/ideas',
      show_fundamentals: false,
      show_params: window.location.pathname === '/structure/autocallable/ideas',
      coupon_type: 'All',
      coupon_min: 0,
      coupon_max: 100,
      tenor_min: 0,
      tenor_max: 120,
      barrier_min: 0,
      barrier_max: 100,
      strike_min: 0,
      strike_max: 100,
      offset: 0,
      more_structures: true
    };
    this.updateColumnName = this.updateColumnName.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
    this.resetConfig = this.resetConfig.bind(this);
    this.toggleCompoentShow = this.toggleCompoentShow.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.saveFilters = this.saveFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.openFactSheet = this.openFactSheet.bind(this);
    this.approveProduct = this.approveProduct.bind(this);
    this.fetchNotes = this.fetchNotes.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  updateColumnName(event) {
    if (event.target.checked) {
      this.setState((prevState) => ({
        columns: [...prevState.columns, event.target.id]
      }));
    } else {
      const columns = this.state.columns;
      columns.splice(columns.indexOf(event.target.id), 1);
      this.setState({
        columns
      });
    }
  }

  async saveConfig() {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      const data = { structure_columns: this.state.columns };

      axios
        .patch(`${base_url}/structure-columns/${cookies.get('User-Specified')}`, data, config)
        .then(() => {
          Toast.success('Success', 1000, () => {});
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    Toast.success('Success', 1000, () => {});
  }

  async resetConfig() {
    const defaultColumns = [
      'Name',
      'Sector',
      'Initial Price',
      'Last Price',
      '% Change',
      'Barrier Price',
      'Distance To Barrier',
      'Autocall Level',
      'Distance To Autocall'
    ];
    Toast.loading('In Progress', () => {});
    this.setState({
      columns: defaultColumns
    });

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        Authorization: cookies.get('Authorization')
      };
      const data = { structure_columns: defaultColumns };

      axios
        .patch(`${base_url}/structure-columns/${cookies.get('User-Specified')}`, data, config)
        .then(() => {})
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
    Toast.success('Success', 1000, () => {});
  }

  toggleCompoentShow(event) {
    if (event.target.id === 'Basic') {
      this.setState({
        show_basic: true,
        show_fundamentals: false,
        show_params: false
      });
    } else if (event.target.id === 'Fundamentals') {
      this.setState({
        show_basic: false,
        show_fundamentals: true,
        show_params: false
      });
    } else if (event.target.id === 'Data') {
      this.setState({
        show_basic: false,
        show_fundamentals: false,
        show_params: true
      });
    } else {
      this.setState({
        show_basic: true,
        show_fundamentals: true,
        show_params: false
      });
    }
  }

  updateFilter(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  async saveFilters() {
    Toast.loading('In Progress', () => {});

    await fetchAuthSession().then(() => {
      const config = {};
      switch (this.props.page) {
        case '/structure/autocallable/details':
          config.params = {
            permanent: true,
            expired: false
          };
          break;
        case '/structure/autocallable/ideas':
          config.params = {
            permanent: false,
            expired: false
          };
          break;
        case '/structure/autocallable/expired/details':
          config.params = {
            permanent: true,
            expired: true
          };
          break;
        case '/structure/autocallable/pending/details':
          config.params = {
            is_pending: true,
            permanent: false
          };
          break;
        case '/structure/autocallable/curated/details':
          config.params = {
            curated: true
          };
          break;
        default:
          break;
      }

      if (this.state.coupon_type !== 'All') {
        config.params.screener = true;
        config.params.coupon_type = this.state.coupon_type;
      }
      if (this.state.coupon_min !== 0) {
        config.params.screener = true;
        config.params.coupon_min = this.state.coupon_min;
      }
      if (this.state.coupon_max !== 100) {
        config.params.screener = true;
        config.params.coupon_max = this.state.coupon_max;
      }
      if (this.state.tenor_min !== 0) {
        config.params.screener = true;
        config.params.tenor_min = this.state.tenor_min;
      }
      if (this.state.tenor_max !== 120) {
        config.params.screener = true;
        config.params.tenor_max = this.state.tenor_max;
      }
      if (this.state.barrier_min !== 0) {
        config.params.screener = true;
        config.params.barrier_min = this.state.barrier_min;
      }
      if (this.state.barrier_max !== 100) {
        config.params.screener = true;
        config.params.barrier_max = this.state.barrier_max;
      }
      if (this.state.strike_min !== 0) {
        config.params.screener = true;
        config.params.strike_min = this.state.strike_min;
      }
      if (this.state.strike_max !== 100) {
        config.params.screener = true;
        config.params.strike_max = this.state.strike_max;
      }
      if (cookies.get('Portfolio-Specified') != null) {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      }
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/structure-columns/${cookies.get('User-Specified')}`, config)
        .then(async (res) => {
          this.setState({
            columns: res.data.items
          });
        });
      axios.get(`${base_url}/autocallable-structure`, config).then(async (res) => {
        this.setState({
          product_details: res.data.item.items
        });
      });
      config.params = {};
      axios.get(`${base_url}/structure-underlying-count`, config).then(async (res) => {
        this.setState({
          underlying_count: res.data.item
        });
      });
    });
    Toast.success('Success', 1000, () => {});
  }

  async resetFilters() {
    this.setState({
      coupon_type: 'All',
      coupon_min: 0,
      coupon_max: 100,
      tenor_min: 0,
      tenor_max: 120,
      barrier_min: 0,
      barrier_max: 100,
      strike_min: 0,
      strike_max: 100
    });
    this.saveFilters();
  }

  async approveProduct(isinCode) {
    Toast.loading('In Progress', () => {});

    if (cookies.get('Type-Specified') !== 'Admin') {
      Toast.fail('You are not authorized to approve product', 3000, () => {});
      return;
    }

    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      config.params = {
        attribute: 'is_pending',
        value: null
      };
      axios
        .patch(`${base_url}/structure-attributes/${isinCode}`, {}, config)
        .then(() => {
          Toast.success('Success', 1000, () => {
            window.location.href = '/structure/autocallable/ideas';
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async sendEmail(isin_code, userId) {
    await fetchAuthSession().then(() => {
      const config = {};
      const data = {
        isin_code,
        user_id: userId
      };
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .post(`${base_url}/send-email`, data, config)
        .then(() => {
          Toast.success('Success', 1000, () => {});
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  async openFactSheet(isin_code) {
    window.open(
      `https://${bucket_name}.s3.${region}.amazonaws.com/structures/term-sheets/${isin_code}.pdf`,
      '_blank'
    );
  }

  async fetchData() {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };
      axios
        .get(`${base_url}/structure-columns/${cookies.get('User-Specified')}`, config)
        .then(async (res) => {
          this.setState({
            columns: res.data.items
          });
        });
    });
    this.fetchNotes();
  }

  async fetchNotes() {
    await fetchAuthSession().then(() => {
      const config = {};
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      switch (this.props.page) {
        case '/structure/autocallable/details':
          config.params = {
            permanent: true,
            expired: false
          };
          break;
        case '/structure/autocallable/ideas':
          config.params = {
            permanent: false,
            expired: false
          };
          break;
        case '/structure/autocallable/expired/details':
          config.params = {
            permanent: true,
            expired: true
          };
          break;
        case '/structure/autocallable/pending/details':
          config.params = {
            is_pending: true,
            permanent: false
          };
          break;
        case '/structure/autocallable/curated/details':
          config.params = {
            curated: true
          };
          break;
        default:
          break;
      }

      if (cookies.get('Portfolio-Specified') != null) {
        config.params.portfolio_id = cookies.get('Portfolio-Specified');
      }

      config['params']['offset'] = this.state.offset;

      axios.get(`${base_url}/autocallable-structure`, config).then(async (res) => {
        this.setState((prevState) => {
          if (
            !_.isEmpty(_.get(res, 'data.item.items[0]')) &&
            !_.includes(
              prevState.product_details.map((item) => item.isin_code),
              res.data.item.items[0].isin_code
            )
          ) {
            return {
              product_details: [...prevState.product_details, ...res.data.item.items],
              total_notional_quantities: res.data.item.total_notional_quantities,
              offset: res.data.item.offset,
              more_structures: res.data.item.items.length > 0,
              userType: cookies.get('Type-Specified'),
              show_basic: this.props.page !== '/structure/autocallable/ideas',
              show_fundamentals: false,
              show_params: this.props.page === '/structure/autocallable/ideas'
            };
          } else {
            return prevState;
          }
        });
        Toast.success('Success', 1000, () => {});
      });

      config.params = {};
      axios.get(`${base_url}/structure-underlying-count`, config).then(async (res) => {
        this.setState({
          underlying_count: res.data.item
        });
      });
      config.params = {};
      axios.get(`${base_url}/structure-holding-count`, config).then(async (res) => {
        this.setState({
          holding_count: res.data.item
        });
      });
    });
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      window.location.reload();
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container row">
          <div className="col-sm-10">
            <h3 style={{ color: 'red' }}>Structure Products</h3>
          </div>
          <div className="col-sm-2">
            <div className="btn-group">
              <button className="btn btn-primary" data-toggle="modal" data-target="#columns">
                <FaCog />
              </button>

              <button className="btn btn-primary" data-toggle="modal" data-target="#screener">
                <FaFilter />
              </button>
              <button
                className="btn btn-primary row"
                disabled={!this.state.more_structures}
                onClick={this.fetchNotes}>
                {' '}
                {this.state.more_structures ? 'Load More' : 'Loaded All'}{' '}
              </button>
            </div>

            <div className="modal" id="columns">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">columns</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Code"
                            checked={this.state.columns.includes('Code') && true}
                          />
                          <label className="form-group form-check-label hidden">Code</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Sector"
                            checked={this.state.columns.includes('Sector') && true}
                          />
                          <label className="form-group form-check-label hidden">Sector</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Industry"
                            checked={this.state.columns.includes('Industry') && true}
                          />
                          <label className="form-group form-check-label hidden">Industry</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Barrier Price"
                            checked={this.state.columns.includes('Barrier Price') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Barrier Price
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Distance To Barrier"
                            checked={this.state.columns.includes('Distance To Barrier') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Distance To Barrier
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Coupon Level"
                            checked={this.state.columns.includes('Coupon Level') && true}
                          />
                          <label className="form-group form-check-label hidden">Coupon Level</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Distance To Coupon"
                            checked={this.state.columns.includes('Distance To Coupon') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Distance To Coupon
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Autocall Level"
                            checked={this.state.columns.includes('Autocall Level') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Autocall Level
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Distance To Autocall"
                            checked={this.state.columns.includes('Distance To Autocall') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Distance To Autocall
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Next Autocall %"
                            checked={this.state.columns.includes('Next Autocall %') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Next Autocall %
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Strike Price"
                            checked={this.state.columns.includes('Strike Price') && true}
                          />
                          <label className="form-group form-check-label hidden">Strike Price</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Distance To Strike"
                            checked={this.state.columns.includes('Distance To Strike') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Distance To Strike
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="First Autocall Date"
                            checked={this.state.columns.includes('First Autocall Date') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            First Autocall Date
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Initial Fixing Date"
                            checked={this.state.columns.includes('Initial Fixing Date') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Initial Fixing Date
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Next Autocall Date"
                            checked={this.state.columns.includes('Next Autocall Date') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Next Autocall Date
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Next Autocall Level"
                            checked={this.state.columns.includes('Next Autocall Level') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Next Autocall Level
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Status"
                            checked={this.state.columns.includes('Status') && true}
                          />
                          <label className="form-group form-check-label hidden">Status</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Type"
                            checked={this.state.columns.includes('Type') && true}
                          />
                          <label className="form-group form-check-label hidden">Type</label>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Fundamentals"
                            checked={this.state.columns.includes('Fundamentals') && true}
                          />
                          <label className="form-group form-check-label hidden">Funamentals</label>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.updateColumnName}
                            id="Analyst Ratings"
                            checked={this.state.columns.includes('Analyst Ratings') && true}
                          />
                          <label className="form-group form-check-label hidden">
                            Analyst Ratings
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer btn-group">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      data-dismiss="modal"
                      onClick={this.saveConfig}>
                      Save Configuration <FaCloud />
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-block"
                      data-dismiss="modal"
                      onClick={this.resetConfig}>
                      Reset Configuration <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal" id="screener">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Filters</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="form-check">
                      <label className="form-group form-label">Coupon Type:</label>
                      <select
                        type="text"
                        className="form-control"
                        onChange={this.updateFilter}
                        id="coupon_type"
                        value={this.state.coupon_type}>
                        <option>All</option>
                        <option>Guaranteed</option>
                        <option>Memory</option>
                        <option>Without Memory</option>
                      </select>

                      <label className="form-group form-label">Coupon:</label>
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="coupon_min"
                          value={this.state.coupon_min}
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="coupon_max"
                          value={this.state.coupon_max}
                        />
                      </div>

                      <label className="form-group form-label">Tenor:</label>
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="tenor_min"
                          value={this.state.tenor_min}
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="tenor_max"
                          value={this.state.tenor_max}
                        />
                      </div>

                      <label className="form-group form-label">Barrier:</label>
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="barrier_min"
                          value={this.state.barrier_min}
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="barrier_max"
                          value={this.state.barrier_max}
                        />
                      </div>

                      <label className="form-group form-label">Strike:</label>
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="strike_min"
                          value={this.state.strike_min}
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.updateFilter}
                          id="strike_max"
                          value={this.state.strike_max}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        onClick={this.saveFilters}>
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={this.resetFilters}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group" style={{ width: '100%' }}>
          <button
            type="button"
            className="btn btn-secondary"
            id="Data"
            onClick={this.toggleCompoentShow}>
            Data
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            id="Basic"
            onClick={this.toggleCompoentShow}>
            Basic
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            id="Fundamentals"
            onClick={this.toggleCompoentShow}>
            Fundamentals
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            id="All"
            onClick={this.toggleCompoentShow}>
            All
          </button>
        </div>
        {this.state.show_params ? (
          <table className="table table-sm">
            <thead className="table-dark">
              <tr>
                <th style={{ position: 'sticky', top: 50 }}>ISIN CODE</th>
                {this.props.page !== '/structure/autocallable/ideas' && (
                  <th style={{ position: 'sticky', top: 50 }}>Issuer</th>
                )}
                <th style={{ position: 'sticky', top: 50 }}>Underlying</th>
                <th style={{ position: 'sticky', top: 50 }}>Currency</th>
                <th style={{ position: 'sticky', top: 50 }}>Coupon</th>
                <th style={{ position: 'sticky', top: 50 }}>CT</th>
                {this.props.page !== '/structure/autocallable/ideas' && (
                  <th style={{ position: 'sticky', top: 50 }}>Tenor</th>
                )}
                {this.props.page !== '/structure/autocallable/ideas' && (
                  <th style={{ position: 'sticky', top: 50 }}>Issue Date</th>
                )}
                <th style={{ position: 'sticky', top: 50 }}>Maturity</th>
                <th style={{ position: 'sticky', top: 50 }}>Barrier Type</th>
                <th style={{ position: 'sticky', top: 50 }}>Barrier</th>
                <th style={{ position: 'sticky', top: 50 }}>Strike</th>
                <th style={{ position: 'sticky', top: 50 }}>CT</th>
                <th style={{ position: 'sticky', top: 50 }}>AT</th>
                <th style={{ position: 'sticky', top: 50 }}>Autocall Frequency</th>
                <th style={{ position: 'sticky', top: 50 }}>Autocall Step</th>
                <th style={{ position: 'sticky', top: 50 }}>Special Features</th>
                {this.props.page !== '/structure/autocallable/ideas' && (
                  <th style={{ position: 'sticky', top: 50 }}>Basket Price</th>
                )}
              </tr>
            </thead>
            <tbody className="custom-info">
              {this.state.product_details.map((isin_data, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: '#002850',
                        color: '#ffffff',
                        textDecoration: 'none',
                        border: '1px solid white'
                      }}>
                      <strong dataToggle="tooltip" title={isin_data.last_updated}>
                        <Link to={`/structure/autocallable/report?ISIN=${isin_data.isin_code}`}>
                          {isin_data.isin_code}
                        </Link>
                      </strong>
                    </td>
                    {this.props.page !== '/structure/autocallable/ideas' && (
                      <td>{isin_data.issuer ? isin_data.issuer : '-'}</td>
                    )}
                    <td>{getCodes(isin_data.underlying)}</td>
                    <td>{isin_data.currency ? isin_data.currency : '-'}</td>
                    <td>{isin_data.coupon ? isin_data.coupon : '-'}</td>
                    <td>{isin_data.coupon_type ? isin_data.coupon_type : '-'}</td>
                    {this.props.page !== '/structure/autocallable/ideas' && (
                      <td>{isin_data.tenor ? isin_data.tenor : '-'}</td>
                    )}
                    {this.props.page !== '/structure/autocallable/ideas' && (
                      <td>{isin_data.issue_date ? isin_data.issue_date : '-'}</td>
                    )}
                    <td>{isin_data.maturity ? isin_data.maturity : '-'}</td>
                    <td>{isin_data.barrier_type ? isin_data.barrier_type : '-'}</td>
                    <td>{isin_data.barrier ? isin_data.barrier : '-'}</td>
                    <td>{isin_data.strike ? isin_data.strike : '-'}</td>
                    <td>{isin_data.coupon_percentage ? isin_data.coupon_percentage : '-'}</td>
                    <td>{isin_data.autocall_trigger ? isin_data.autocall_trigger : '-'}</td>
                    <td>{isin_data.autocall_frequency ? isin_data.autocall_frequency : '-'}</td>
                    <td>{isin_data.autocall_step ? isin_data.autocall_step : '-'}</td>
                    <td>{isin_data.special_features ? isin_data.special_features : '-'}</td>
                    {this.props.page !== '/structure/autocallable/ideas' && (
                      <td>{isin_data.basket_price_latest ? isin_data.basket_price_latest : '-'}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <table className="table table-sm">
            <thead className="table-dark">
              <tr>
                <th style={{ position: 'sticky', top: 50 }}>ISIN Code</th>
                <th style={{ position: 'sticky', top: 50 }}>Parameters</th>
                <th style={{ position: 'sticky', top: 50 }}>Dates</th>

                {this.state.columns.includes('Name') && (
                  <th style={{ position: 'sticky', top: 50 }}>Name</th>
                )}
                <th style={{ position: 'sticky', top: 50 }}></th>
                {this.state.show_basic && (
                  <>
                    {this.state.columns.includes('Code') && (
                      <th style={{ position: 'sticky', top: 50 }}>Code</th>
                    )}
                    {this.state.columns.includes('Sector') && (
                      <th style={{ position: 'sticky', top: 50 }}>Sector</th>
                    )}
                    {this.state.columns.includes('Industry') && (
                      <th style={{ position: 'sticky', top: 50 }}>Industry</th>
                    )}
                    {this.state.columns.includes('Initial Price') && (
                      <th style={{ position: 'sticky', top: 50 }}>Initial Price</th>
                    )}
                    {this.state.columns.includes('Last Price') && (
                      <th style={{ position: 'sticky', top: 50 }}>Last Price</th>
                    )}
                    {this.state.columns.includes('% Change') && (
                      <th style={{ position: 'sticky', top: 50 }}>% Change</th>
                    )}
                    {this.state.columns.includes('Barrier Price') && (
                      <th style={{ position: 'sticky', top: 50 }}>Barrier Price</th>
                    )}
                    {this.state.columns.includes('Distance To Barrier') && (
                      <th style={{ position: 'sticky', top: 50 }}>Distance To Barrier</th>
                    )}
                    {this.state.columns.includes('Autocall Level') && (
                      <th style={{ position: 'sticky', top: 50 }}>Autocall Level</th>
                    )}
                    {this.state.columns.includes('Distance To Autocall') && (
                      <th style={{ position: 'sticky', top: 50 }}>Distance To Autocall</th>
                    )}
                    {this.state.columns.includes('Coupon Level') && (
                      <th style={{ position: 'sticky', top: 50 }}>Coupon Level</th>
                    )}
                    {this.state.columns.includes('Distance To Coupon') && (
                      <th style={{ position: 'sticky', top: 50 }}>Distance To Coupon</th>
                    )}
                    {this.state.columns.includes('Strike Price') && (
                      <th style={{ position: 'sticky', top: 50 }}>Strike Price</th>
                    )}
                    {this.state.columns.includes('Distance To Strike') && (
                      <th style={{ position: 'sticky', top: 50 }}>Distance To Strike</th>
                    )}
                    {this.state.columns.includes('Basket Price Latest') && (
                      <th style={{ position: 'sticky', top: 50 }}>Basket Price Latest</th>
                    )}
                    {this.state.columns.includes('First Autocall Date') && (
                      <th style={{ position: 'sticky', top: 50 }}>First Autocall Date</th>
                    )}
                    {this.state.columns.includes('Next Autocall Date') && (
                      <th style={{ position: 'sticky', top: 50 }}>Next Autocall Date</th>
                    )}
                    {this.state.columns.includes('Next Autocall %') && (
                      <th style={{ position: 'sticky', top: 50 }}>Next Autocall %</th>
                    )}
                    {this.state.columns.includes('Next Autocall Level') && (
                      <th style={{ position: 'sticky', top: 50 }}>Next Autocall Level</th>
                    )}
                    {this.state.columns.includes('Status') && (
                      <th style={{ position: 'sticky', top: 50 }}>Status</th>
                    )}
                  </>
                )}

                {this.state.show_fundamentals && (
                  <>
                    <th style={{ position: 'sticky', top: 50 }}></th>
                    <th style={{ position: 'sticky', top: 50 }}>Trailing PE</th>
                    <th style={{ position: 'sticky', top: 50 }}>Forward PE</th>
                    <th style={{ position: 'sticky', top: 50 }}>MCap to Revenue</th>
                    <th style={{ position: 'sticky', top: 50 }}>ROE</th>
                    <th style={{ position: 'sticky', top: 50 }}>Dividend Yield</th>
                    <th style={{ position: 'sticky', top: 50 }}></th>
                    <th style={{ position: 'sticky', top: 50 }}>Consensus Ratings</th>
                    <th style={{ position: 'sticky', top: 50 }}>Buy</th>
                    <th style={{ position: 'sticky', top: 50 }}>Hold</th>
                    <th style={{ position: 'sticky', top: 50 }}>Sell</th>
                    <th style={{ position: 'sticky', top: 50 }}>12 Months Target</th>
                    <th style={{ position: 'sticky', top: 50 }}>Return</th>
                  </>
                )}
                <th style={{ position: 'sticky', top: 50 }}></th>
              </tr>
            </thead>
            <tbody className="custom-info">
              {this.state.product_details.map((isin_data, index) => {
                return (
                  <>
                    <tr>
                      <td
                        key={isin_data}
                        className="table-dark"
                        rowSpan={isin_data.underlying.length + 1}
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white'
                        }}>
                        <strong dataToggle="tooltip" title={isin_data.last_updated}>
                          <Link to={`/structure/autocallable/report?ISIN=${isin_data.isin_code}`}>
                            {isin_data.isin_code}
                          </Link>
                        </strong>
                        <br />
                        <strong>{isin_data.issuer}</strong>
                        <br />
                        <strong>{isin_data.coupon}% p.a.</strong>
                        <br />
                        <strong>
                          {isin_data.coupon_type !== 'Conditional with Memory'
                            ? isin_data.coupon_type
                            : 'Condtl with Memory'}
                        </strong>
                        <br />
                        <strong>
                          {this.state.total_notional_quantities[isin_data.isin_code] != null &&
                            this.state.total_notional_quantities[isin_data.isin_code] !== 0 &&
                            this.state.total_notional_quantities[
                              isin_data.isin_code
                            ].toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                        </strong>
                        <br />
                        <strong>
                          {isin_data.basket_price_latest != null &&
                            isin_data.basket_price_latest !== 0 &&
                            isin_data.basket_price_latest.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })}
                        </strong>
                      </td>
                      <td
                        className="table-dark"
                        rowSpan={isin_data.underlying.length + 1}
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white',
                          whiteSpace: 'nowrap'
                        }}>
                        <strong>{isin_data.barrier_type}</strong>
                        <br />
                        <strong>CB: {isin_data.barrier + '%'}</strong>
                        <br />
                        <strong>ST: {isin_data.strike + '%'}</strong>
                        <br />
                        <strong>
                          CT:
                          {isin_data.coupon_percentage ? isin_data.coupon_percentage + '%' : '-'}
                        </strong>
                        <br />
                        <strong>AC: {isin_data.autocall_percentage + '%'}</strong>
                        <br />
                        <strong>AC Step: {isin_data.autocall_step + '%'}</strong>
                        <br />
                        <strong>CP Step: {isin_data.coupon_step + '%'}</strong>
                      </td>
                      <td
                        className="table-dark"
                        rowSpan={isin_data.underlying.length + 1}
                        style={{
                          backgroundColor: '#002850',
                          color: '#ffffff',
                          textDecoration: 'none',
                          border: '1px solid white',
                          whiteSpace: 'nowrap'
                        }}>
                        <strong>
                          In: {dayjs(isin_data.initial_fixing_date).format('DD/MM/YY')}
                        </strong>
                        <br />
                        <strong>
                          Ac: {dayjs(isin_data.next_autocall_date).format('DD/MM/YY')}
                        </strong>
                        <br />
                        <strong>Fn: {dayjs(isin_data.final_fixing_date).format('DD/MM/YY')}</strong>
                        <br />
                        <strong>{isin_data.maturity} Months</strong>
                        <br />
                        <strong>AC Fq: {isin_data.autocall_frequency}</strong>
                        <br />
                        <strong>CP Fq: {isin_data.coupon_frequency}</strong>
                        <br />
                        <strong>Ac Delay: {isin_data.autocall_delay}</strong>
                      </td>
                    </tr>
                    {isin_data.underlying.map((scrip_data, scrip_index) => {
                      return (
                        <tr key={scrip_index}>
                          {
                            <td>
                              <strong>
                                {scrip_data[column_names.Name] &&
                                  scrip_data[column_names.Name].substring(0, 18)}
                              </strong>
                              {scrip_data[column_names.Code].indexOf(' INDEX') !== -1 && (
                                <a
                                  className="btn btn-link"
                                  href={`/product/index-components?code=${
                                    scrip_data[column_names.Code]
                                  }`}>
                                  <FaLayerGroup />
                                </a>
                              )}
                            </td>
                          }
                          <td>
                            {this.state.underlying_count.length !== 0 &&
                              this.props.page !== '/structure/autocallable/pending/details' &&
                              this.state.underlying_count[scrip_data[column_names.Code]] &&
                              this.state.holding_count.length !== 0 &&
                              this.props.page !== '/structure/autocallable/pending/details' && (
                                <span
                                  className={
                                    this.state.underlying_count.length !== 0 &&
                                    this.props.page !== '/structure/autocallable/pending/details' &&
                                    this.state.holding_count[scrip_data[column_names.Code]]
                                      ? 'badge badge-danger'
                                      : 'badge badge-primary'
                                  }>
                                  {this.state.underlying_count[scrip_data[column_names.Code]]}
                                </span>
                              )}
                          </td>
                          {this.state.show_basic && (
                            <>
                              {this.state.columns.includes('Code') &&
                                this.state.columns.includes('Code') && (
                                  <td>
                                    <a
                                      className="btn btn-link"
                                      href={`/product/equity?code=${
                                        scrip_data[column_names.Code]
                                      }`}>
                                      {scrip_data[column_names.Code]}
                                    </a>
                                  </td>
                                )}
                              {this.state.columns.includes('Sector') &&
                                this.state.columns.includes('Sector') && (
                                  <td>
                                    {scrip_data[column_names.Sector]
                                      ? scrip_data[column_names.Sector].substring(0, 18)
                                      : '-'}
                                  </td>
                                )}
                              {this.state.columns.includes('Industry') &&
                                this.state.columns.includes('Industry') && (
                                  <td>{scrip_data[column_names.Industry]}</td>
                                )}
                              {this.state.columns.includes('Initial Price') &&
                                this.state.columns.includes('Initial Price') && (
                                  <td>
                                    {scrip_data[column_names['Initial Price']].toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                    )}
                                  </td>
                                )}
                              {this.state.columns.includes('Last Price') &&
                                this.state.columns.includes('Last Price') && (
                                  <td>
                                    {scrip_data[column_names['Last Price']].toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                    )}
                                  </td>
                                )}
                              {this.state.columns.includes('% Change') &&
                                this.state.columns.includes('% Change') && (
                                  <td
                                    style={{
                                      color:
                                        scrip_data[column_names['% Change']] > 0 ? 'green' : 'red'
                                    }}>
                                    <strong>
                                      {scrip_data[column_names['% Change']].toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                      )}
                                      %
                                    </strong>
                                  </td>
                                )}
                              {this.state.columns.includes('Barrier Price') && (
                                <td>
                                  {scrip_data[column_names['Barrier Price']]
                                    ? scrip_data[column_names['Barrier Price']].toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                      )
                                    : '-'}
                                </td>
                              )}
                              {this.state.columns.includes('Distance To Barrier') && (
                                <td
                                  style={{
                                    color:
                                      scrip_data[column_names['Distance To Barrier']] > 0
                                        ? 'green'
                                        : 'red'
                                  }}>
                                  <strong>
                                    {scrip_data[column_names['Distance To Barrier']] &&
                                    scrip_data[column_names['Distance To Barrier']] !== -100
                                      ? scrip_data[
                                          column_names['Distance To Barrier']
                                        ].toLocaleString(undefined, {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 2
                                        }) + '%'
                                      : '-'}
                                  </strong>
                                </td>
                              )}
                              {this.state.columns.includes('Autocall Level') && (
                                <td>
                                  {scrip_data[column_names['Autocall Level']].toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                                </td>
                              )}
                              {this.state.columns.includes('Distance To Autocall') && (
                                <td
                                  style={{
                                    color:
                                      scrip_data[column_names['Distance To Autocall']] > 0
                                        ? 'green'
                                        : 'red'
                                  }}>
                                  <strong>
                                    {scrip_data[
                                      column_names['Distance To Autocall']
                                    ].toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    })}
                                    %
                                  </strong>
                                </td>
                              )}
                              {this.state.columns.includes('Coupon Level') && (
                                <td>
                                  {scrip_data[column_names['Coupon Level']]
                                    ? scrip_data[column_names['Coupon Level']].toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                      )
                                    : '-'}
                                </td>
                              )}
                              {this.state.columns.includes('Distance To Coupon') && (
                                <td
                                  style={{
                                    color:
                                      scrip_data[column_names['Distance To Coupon']] > 0
                                        ? 'green'
                                        : 'red'
                                  }}>
                                  <strong>
                                    {scrip_data[column_names['Distance To Coupon']] &&
                                    scrip_data[column_names['Distance To Coupon']] !== -100
                                      ? scrip_data[
                                          column_names['Distance To Coupon']
                                        ].toLocaleString(undefined, {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 2
                                        }) + '%'
                                      : '-'}
                                  </strong>
                                </td>
                              )}
                              {this.state.columns.includes('Strike Price') && (
                                <td>
                                  {scrip_data[column_names['Strike Price']].toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                                </td>
                              )}
                              {this.state.columns.includes('Distance To Strike') && (
                                <td
                                  style={{
                                    color:
                                      scrip_data[column_names['Distance To Strike']] > 0
                                        ? 'green'
                                        : 'red'
                                  }}>
                                  <strong>
                                    {scrip_data[column_names['Distance To Strike']].toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                    )}
                                    %
                                  </strong>
                                </td>
                              )}
                              {this.state.columns.includes('Basket Price Latest') && (
                                <td>{isin_data[column_names['Basket Price Latest']]}</td>
                              )}
                              {this.state.columns.includes('First Autocall Date') && (
                                <td>
                                  {dayjs(isin_data[column_names['First Autocall Date']]).format(
                                    'DD/MM/YY'
                                  )}
                                </td>
                              )}
                              {this.state.columns.includes('Next Autocall Date') && (
                                <td>
                                  {dayjs(isin_data[column_names['Next Autocall Date']]).format(
                                    'DD/MM/YY'
                                  )}
                                </td>
                              )}
                              {this.state.columns.includes('Next Autocall %') && (
                                <td>{isin_data[column_names['Next Autocall %']]}</td>
                              )}
                              {this.state.columns.includes('Next Autocall Level') && (
                                <td>
                                  {scrip_data[column_names['Next Autocall Level']].toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                  )}
                                </td>
                              )}
                              {this.state.columns.includes('Status') && (
                                <td>{isin_data[column_names.Status]}</td>
                              )}
                              {this.state.columns.includes('Type') && (
                                <td>{isin_data[column_names.Type]}</td>
                              )}
                            </>
                          )}
                          {this.state.show_fundamentals && (
                            <>
                              <td></td>
                              <td>
                                {scrip_data.trailing_pe
                                  ? scrip_data.trailing_pe.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    })
                                  : '-'}
                              </td>
                              <td>
                                {scrip_data.forward_pe
                                  ? scrip_data.forward_pe.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    })
                                  : '-'}
                              </td>
                              <td>
                                {scrip_data.market_capitalization_to_revenue_ttm
                                  ? scrip_data.market_capitalization_to_revenue_ttm.toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 0, maximumFractionDigits: 2 }
                                    )
                                  : '-'}
                              </td>
                              <td>
                                {scrip_data.return_on_equity_ttm
                                  ? scrip_data.return_on_equity_ttm.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    })
                                  : '-'}
                              </td>
                              <td>
                                {scrip_data.dividend_yield
                                  ? scrip_data.dividend_yield.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    })
                                  : '-'}
                              </td>
                              <td></td>
                              <td>{scrip_data.rating ? scrip_data.rating : '-'}</td>
                              {/* <td style={{color: "green"}}><strong>{scrip_data["strong_buy"]? parseInt(scrip_data["strong_buy"]) + parseInt(scrip_data["buy"]): "-"}</strong></td> */}
                              <td style={{ color: 'green' }}>
                                <strong>
                                  {scrip_data.buy != null && scrip_data.strong_buy != null
                                    ? scrip_data.buy + scrip_data.strong_buy
                                    : '-'}
                                </strong>
                              </td>
                              <td style={{ color: 'gold' }}>
                                <strong>
                                  {scrip_data.hold != null ? scrip_data.hold != null : '-'}
                                </strong>
                              </td>
                              <td style={{ color: 'red' }}>
                                <strong>
                                  {scrip_data.sell != null && scrip_data.strong_sell != null
                                    ? scrip_data.sell + scrip_data.strong_sell
                                    : '-'}
                                </strong>
                              </td>
                              {/* <td style={{color: "red"}}><strong>{scrip_data["sell"]? parseInt(scrip_data["sell"]) + parseInt(scrip_data["strong_sell"]): "-"}</strong></td> */}
                              <td>
                                {scrip_data.wall_street_target_price
                                  ? scrip_data.wall_street_target_price.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    })
                                  : '-'}
                              </td>
                              <td>
                                {scrip_data.return_potential
                                  ? scrip_data.return_potential.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                    }) + '%'
                                  : '-'}
                              </td>
                            </>
                          )}
                          {scrip_index === 0 && (
                            <td rowSpan={isin_data.underlying.length}>
                              <StructurePopover
                                isin_code={isin_data.isin_code}
                                userType={this.state.userType}
                                userId={cookies.get('User-Specified')}
                                TermSheet={isin_data.term_sheet}
                                path={this.props.page}
                                sendEmailHandler={this.sendEmail}
                                openFactSheetHandler={this.openFactSheet}
                                approveProductHandler={this.approveProduct}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    <tr>
                      <td
                        colSpan={this.state.columns.length + 1}
                        className="blank-row"
                        style={{ backgroundColor: ' #ffffff', height: '10px' }}></td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default AutocallableStructures;
