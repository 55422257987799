import Toast from 'light-toast';
import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import { Amplify } from 'aws-amplify';
import { fetchAuthSession, signIn } from 'aws-amplify/auth';
import awsconfig from './../aws-exports';

Amplify.configure(awsconfig);

const cookies = new Cookies();

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      clikedLogin: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    Toast.loading('In Progress');
    this.setState({
      clikedLogin: true
    });
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    await signIn({ username, password })
      .then(async () => {
        const user = await fetchAuthSession();
        console.log(user);

        cookies.remove('Authorization', { path: '/' });
        cookies.remove('User-Specified', { path: '/' });
        cookies.remove('Type-Specified', { path: '/' });
        cookies.remove('Portfolio-Specified', { path: '/' });

        cookies.set('Authorization', user.tokens.accessToken.toString(), {
          path: '/'
        });
        cookies.set('User-Specified', user.tokens.accessToken.payload.username, {
          path: '/'
        });
        cookies.set('Vertical-Specified', user.tokens.accessToken.payload['cognito:groups'][0], {
          path: '/'
        });
        cookies.set('Type-Specified', user.tokens.accessToken.payload['cognito:groups'][1], {
          path: '/'
        });
        Toast.success('Logged In Successfully', 2000, () => {});
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
  }

  render() {
    return (
      <div className="container-fluid pt-4">
        <div className="col-sm-3"></div>
        <div className="col-sm-5 card" style={{ width: '50%' }}>
          <form className="card-body">
            <h4 className="card-title">Log In</h4>
            <p className="card-text">Login to Project </p>
            <div className="form-group">
              <label>Name:</label>
              <input type="text" className="form-control" id="username" />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input type="password" className="form-control" id="password" />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-dark"
                onClick={this.handleSubmit}
                disabled={this.state.clikedLogin}>
                Log in
              </button>
            </div>
          </form>
        </div>
        <div className="col-sm-3"></div>
      </div>
    );
  }
}

export default LoginPage;
