import React, { Component } from 'react';

import { Amplify } from 'aws-amplify';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Cookies from 'universal-cookie';
import awsconfig from './../aws-exports';

import Navbar from './navbar';

import LoginPage from './loginPage';

import IndexComponents from './indexComponents';
import IndexComponentsPivot from './indexComponentsPivot';
import IndexProduct from './indexproduct';
import NewsData from './newsData';
import ProductPage from './product';
import BondPage from './productPageBond';
import EtfPage from './productPageEtf';
import FundPage from './productPageFund';
import ProductScreenerEquity from './productScreenerEquity';
import StructureReport from './structureAutocallableReport';
import StructureDispersionWarrentReport from './structureDispersionWarrentReport';
import StructureSharkNoteReport from './structureSharkNoteReport';
import StructureTwinWinReport from './structureTwinWinReport';
import StructureUnderlying from './structureUnderlying';
import TermSheetUpload from './termSheetUpload';

import AutocallableStructures from './autocallableStructures';
import DispersionWarrentStructures from './dispersionWarrentStructures';
import ParticipationTypeStructures from './participationTypeStructures';
import ProductPortfolioInsights from './productPorftfolioInsights';
import ProductBonds from './productsBonds';
import AlertPage from './structureAlerts';
import StructureAdd from './structureAutocallableAdd';
import StructureAutocallableUpdate from './structureAutocallableUpdate';
import StructureCouponCashflow from './structureCouponCashflow';
import StructureDispersionWarrentAdd from './structureDispersionWarrentAdd';
import StructureDispersionWarrentUpdate from './structureDispersionWarrentUpdate';
import StructureMatrix from './structureMatrix';
import StructureSharkNoteAdd from './structureSharkNoteAdd';
import StructureSharkNoteUpdate from './structureSharkNoteUpdate';
import StructureTwinWinAdd from './structureTwinWinAdd';
import StructureTwinWinUpdate from './structureTwinWinUpdate';

import CashView from './cashView';
import HoldingUpdate from './holdingUpdate';
import LedgerAdd from './ledgerAdd';
import PortfolioExposure from './portfolioExposure';
import PortfolioLedger from './portfolioLedger';
import UpdatePurchase from './portfolioUpdate';
import PortfolioView from './portfolioView';
import ViewPortfoliosInsights from './portfolioViewInsights';
import UserPortfolioMapping from './userPortfolioMapping';

import ViewOrders from './orderBook';

import LinkPortfolio from './linkPortfolio';
import AddUser from './userAdd';
import UserUpdate from './userUpdate';

import RMLinkUser from './rmLinkUser';

import Indices from './indices';
import OptionChain from './optionChain';
import OptionMatrix from './optionMatrix';
import OptionsAnalyser from './optionsAnalyser';
import CallWritingStrategies from './optionsCallWritingStrategies';
import OptionsCallWritingStrategy from './optionsCallWritingStrategy';
import OptionGraph from './optionsGraph';
import PutWritingStrategies from './optionsPutWritingStrategies';
import OptionsPutWritingStrategy from './optionsPutWritingStrategy';
import OptionsStrategy from './optionsStrategy';

import SearchQuote from './searchQuote';
import Watchlist from './watchlist';

import HandleStockSplit from './handlerStockSplit';
import Home from './homePage';
import LoginHistory from './loginHistory';
import Profile from './profile';
import Unauthorized from './unauthorised';
import UserLoginAdd from './userLoginAdd';

import RawPayinPayoutAdd from './rawPayinPayoutAdd';
import ETFFunds from './etfFunds';
import PlanetaryMovement from './planetaryMovements';
import IntradayChartPage from './trading/IntradayChartPage';
import TradingView from './tradingView';
import UploadFile from './uploadFile';

import StructureAnalaysis from './structureAnalysis';
import TradingChartPage from './trading/chartPage';

const cookies = new Cookies();

Amplify.configure(awsconfig);

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      userToken: null
    };

    this.userLogin = this.userLogin.bind(this);
  }

  async handleAuth() {
    cookies.remove('User-Specified', { path: '/' });
    cookies.remove('Type-Specified', { path: '/' });
    cookies.remove('Authorization', { path: '/' });
    cookies.remove('Portfolio-Specified', { path: '/' });
    await signOut({ global: true });
    window.location.reload();
  }

  userLogin() {
    this.setState({
      showDialog: true
    });
  }

  async componentDidMount() {
    await this.loadApp();
  }

  async loadApp() {
    try {
      const user = await fetchAuthSession();

      if (cookies.get('Authorization') == null) {
        cookies.remove('Authorization', { path: '/' });
        cookies.remove('User-Specified', { path: '/' });
        cookies.remove('Type-Specified', { path: '/' });
        cookies.remove('Portfolio-Specified', { path: '/' });

        cookies.set('Authorization', user.tokens.accessToken.toString(), {
          path: '/'
        });
        cookies.set('User-Specified', user.tokens.accessToken.payload.username, {
          path: '/'
        });
        cookies.set('Vertical-Specified', user.tokens.accessToken.payload['cognito:groups'][0], {
          path: '/'
        });
        cookies.set('Type-Specified', user.tokens.accessToken.payload['cognito:groups'][1], {
          path: '/'
        });
      }

      this.setState({
        loggedIn: true,
        isAdmin: cookies.get('Type-Specified') === 'Admin',
        isBackOffice: cookies.get('Type-Specified') === 'BackOffice',
        isExternal: cookies.get('Type-Specified') === 'External',
        userToken: cookies.get('Authorization'),
        showDialog: true
      });
    } catch {
      console.log('Not Authenticated');
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Router>
          {!this.state.loggedIn ? (
            <LoginPage handleLogin={this.userLogin} />
          ) : (
            <>
              <Navbar
                isUserLoggedIn={this.state.loggedIn}
                handleLogout={this.handleAuth}
                admin={this.state.isAdmin || this.state.isBackOffice}
              />
              <div className="App" style={{ paddingTop: '70px' }}>
                <div className="Page">
                  <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/structure/autocallable/add" element={<StructureAdd />} />
                    <Route path="/structure/twin-win/add" element={<StructureTwinWinAdd />} />
                    <Route path="/structure/shark-note/add" element={<StructureSharkNoteAdd />} />
                    <Route
                      path="/structure/dispersion-warrent/add"
                      element={<StructureDispersionWarrentAdd />}
                    />
                    <Route
                      path="/structure/autocallable/ideas"
                      element={<AutocallableStructures page="/structure/autocallable/ideas" />}
                    />
                    <Route
                      path="/structure/autocallable/details"
                      exact
                      element={<AutocallableStructures page="/structure/autocallable/details" />}
                    />
                    <Route
                      path="/structure/autocallable/expired/details"
                      exact
                      element={
                        <AutocallableStructures page="/structure/autocallable/expired/details" />
                      }
                    />
                    <Route
                      path="/structure/autocallable/curated/details"
                      exact
                      element={
                        <AutocallableStructures page="/structure/autocallable/curated/details" />
                      }
                    />
                    <Route
                      path="/structure/autocallable/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <StructureAutocallableUpdate />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route path="/structure/autocallable/report" element={<StructureReport />} />
                    <Route
                      path="/structure/autocallable/underlying"
                      element={<StructureUnderlying />}
                    />
                    <Route
                      path="/structure/autocallable/portfolio-insights"
                      element={<ProductPortfolioInsights />}
                    />
                    <Route
                      path="/structure/autocallable/term-sheet"
                      element={<TermSheetUpload />}
                    />
                    <Route
                      path="/structure/dispersion-warrent/report"
                      element={<StructureDispersionWarrentReport />}
                    />
                    <Route
                      path="/structure/dispersion-warrent/ideas"
                      element={
                        <DispersionWarrentStructures page="/structure/dispersion-warrent/ideas" />
                      }
                    />
                    <Route
                      path="/structure/dispersion-warrent/details"
                      exact
                      element={
                        <DispersionWarrentStructures page="/structure/dispersion-warrent/details" />
                      }
                    />
                    <Route
                      path="/structure/dispersion-warrent/expired/details"
                      exact
                      element={
                        <DispersionWarrentStructures page="/structure/dispersion-warrent/expired/details" />
                      }
                    />
                    <Route
                      path="/structure/dispersion-warrent/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <StructureDispersionWarrentUpdate />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route path="/structure/twin-win/report" element={<StructureTwinWinReport />} />
                    <Route
                      path="/structure/twin-win/ideas"
                      element={<ParticipationTypeStructures />}
                    />
                    <Route
                      path="/structure/twin-win/details"
                      exact
                      element={<ParticipationTypeStructures />}
                    />
                    <Route
                      path="/structure/twin-win/expired/details"
                      exact
                      element={<ParticipationTypeStructures />}
                    />
                    <Route
                      path="/structure/twin-win/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <StructureTwinWinUpdate />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/structure/shark-note/report"
                      element={<StructureSharkNoteReport />}
                    />
                    <Route
                      path="/structure/shark-note/ideas"
                      element={<ParticipationTypeStructures />}
                    />
                    <Route
                      path="/structure/shark-note/details"
                      exact
                      element={<ParticipationTypeStructures />}
                    />
                    <Route
                      path="/structure/shark-note/expired/details"
                      exact
                      element={<ParticipationTypeStructures />}
                    />
                    <Route
                      path="/structure/shark-note/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <StructureSharkNoteUpdate />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route path="/structure/matrix" element={<StructureMatrix />} />
                    <Route path="/structure/analysis" element={<StructureAnalaysis />} />
                    <Route
                      path="/structure/coupon-cashflow"
                      exact
                      element={<StructureCouponCashflow />}
                    />
                    <Route
                      path="/add-user"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <AddUser />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <UserPortfolioMapping />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/user/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <UserUpdate />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/link-portfolio"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <LinkPortfolio />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/relationship-management/link-user"
                      exact
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <RMLinkUser />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/login-history"
                      exact
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <LoginHistory />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/add-login"
                      exact
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <UserLoginAdd />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/portfolio/add"
                      exact
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <LedgerAdd />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/portfolio/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <UpdatePurchase />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="/upload/:sheetType"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <UploadFile />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route path="/portfolio/all" exact element={<PortfolioView />} />
                    <Route path="/portfolio/cash" exact element={<CashView />} />
                    <Route path="/portfolio/exposure" exact element={<PortfolioExposure />} />
                    <Route path="/portfolio/ledger" element={<PortfolioLedger />} />
                    <Route
                      path="/holding/update"
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <HoldingUpdate />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route path="/portfolio/insights" exact element={<ViewPortfoliosInsights />} />
                    <Route path="/portfolio/orders" exact element={<ViewOrders />} />
                    <Route path="/portfolios" exact element={<UserPortfolioMapping />} />
                    <Route path="/product/equity" element={<ProductPage />} />
                    <Route path="/product/indices" exact element={<Indices />} />
                    <Route path="/product/index" element={<IndexProduct />} />
                    <Route path="/product/index-components" element={<IndexComponents />} />
                    <Route
                      path="/product/index-components-pivot"
                      element={<IndexComponentsPivot />}
                    />
                    <Route path="/product/bond" exact element={<BondPage />} />
                    <Route path="/product/fund" exact element={<FundPage />} />
                    <Route path="/product/etf" exact element={<EtfPage />} />
                    <Route path="/product/etfs" exact element={<ETFFunds />} />
                    <Route path="/product/news/:id" element={<NewsData />} />
                    <Route path="/product/bonds" exact element={<ProductBonds />} />
                    <Route path="/screener/alert" element={<AlertPage />} />
                    <Route path="/screener/equity" element={<ProductScreenerEquity />} />
                    <Route path="/watchlist" element={<Watchlist />} />
                    <Route path="/options/option-matrix" exact element={<OptionMatrix />} />
                    <Route path="/options/option-chain" exact element={<OptionChain />} />
                    <Route path="/options/surface-graphs" exact element={<OptionGraph />} />
                    <Route path="/options/analyse" exact element={<OptionsAnalyser />} />
                    <Route path="/options/strategy" exact element={<OptionsStrategy />} />
                    <Route
                      path="/options/put-writing-strategy"
                      exact
                      element={<OptionsPutWritingStrategy />}
                    />
                    <Route
                      path="/options/put-writing-strategies/codes"
                      exact
                      element={<PutWritingStrategies />}
                    />
                    <Route
                      path="/options/put-writing-strategies/index"
                      element={<PutWritingStrategies />}
                    />
                    <Route
                      path="/options/call-writing-strategy"
                      exact
                      element={<OptionsCallWritingStrategy />}
                    />
                    <Route
                      path="/options/call-writing-strategies/codes"
                      exact
                      element={<CallWritingStrategies />}
                    />
                    <Route
                      path="/options/call-writing-strategies/index"
                      element={<CallWritingStrategies />}
                    />
                    <Route path="/quote/search" exact element={<SearchQuote />} />
                    <Route path="/stock-split" exact element={<HandleStockSplit />} />
                    <Route path="/profile" exact element={<Profile />} />
                    <Route path="/chart" exact element={<TradingView />} />
                    <Route path="/live-chart" exact element={<IntradayChartPage />} />
                    <Route path="/planetary-movement" exact element={<PlanetaryMovement />} />
                    <Route path="/raw-payin-payout" element={<RawPayinPayoutAdd />} />
                    <Route
                      path="/trading/chart"
                      exact
                      element={
                        this.state.isAdmin || this.state.isBackOffice ? (
                          <TradingChartPage />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                  </Routes>
                </div>
              </div>
            </>
          )}
        </Router>
      </div>
    );
  }
}

export default MainPage;
