import axios from 'axios';
import Toast from 'light-toast';
import React, { Component } from 'react';
import {
  FaBell,
  FaBoxOpen,
  FaChartBar,
  FaDollarSign,
  FaFilter,
  FaHome,
  FaLightbulb,
  FaPersonBooth
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Flags from './flags';

import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsconfig from '../aws-exports';

import cookies from '../utils/cookies.util';
import base_url from '../utils/request.util';

Amplify.configure(awsconfig);

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: 'Client',
      user_name: cookies.get('User-Specified'),
      code: '',
      suggestions: []
    };

    this.logOutUser = this.logOutUser.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    // this.selectedScrip = this.selectedScrip.bind(this);
  }

  handleChange(event) {
    let code = event.target.value;
    code = code.toUpperCase();
    this.setState({
      code
    });
  }

  async fetchSuggestions(event) {
    this.setState({
      code: event.target.value
    });
    if (event.target.value === '') return;

    await fetchAuthSession().then(() => {
      const config = {};
      config.params = { q: event.target.value };
      config.headers = {
        Authorization: cookies.get('Authorization'),
        'User-Specified': cookies.get('User-Specified'),
        'Type-Specified': cookies.get('Type-Specified')
      };

      axios
        .get(`${base_url}/product/suggest`, config)
        .then((res) => {
          this.setState({
            suggestions: res.data.items
          });
        })
        .catch((error) => {
          console.log(error);
          Toast.fail('Error Performing Action', 3000);
        });
    });
  }

  // selectedScrip(event) {
  //     this.setState({
  //         code: event.target.id,
  //         suggestions: []
  //     }, () => {
  //         this.handleSubmit();
  //     });
  // }

  logOutUser() {
    this.props.handleLogout();
  }

  async componentDidMount() {
    this.setState({ userType: cookies.get('Type-Specified') });
  }

  render() {
    return (
      <>
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
          <Link className="navbar-brand my-auto" to="/">
            Project Calculus
          </Link>

          <ul className="navbar-nav ml-auto">
            <form className="d-flex">
              <input
                type="text"
                id="code"
                value={this.state.code}
                className="form-control"
                placeholder="Search quotes"
                onChange={this.fetchSuggestions}
                autoComplete="off"
              />
            </form>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaHome />
                </strong>
                &nbsp; Home
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/options/open-overview">
                  Portfolio Overview
                </Link>
                <Link className="dropdown-item" to="/options/open-performance">
                  Portfolio Performance
                </Link>
                <Link className="dropdown-item" to="/options/open-attribution">
                  Portfolio Attribution
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaDollarSign />
                </strong>
                &nbsp; Portfolio
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/portfolio/orders">
                  Order Book
                </Link>
                <Link className="dropdown-item" to="/portfolio/cash">
                  Cash
                </Link>
                <Link className="dropdown-item" to="/portfolio/bond">
                  Bond
                </Link>
                <Link className="dropdown-item" to="/portfolio/insights">
                  Insights
                </Link>
                <Link className="dropdown-item" to="/portfolio/structure">
                  Structure
                </Link>
                <Link className="dropdown-item" to="/portfolio/funds">
                  Funds
                </Link>
                <Link className="dropdown-item" to="/portfolio/commodity">
                  Commodity
                </Link>
                <Link className="dropdown-item" to="/portfolio/others">
                  Others
                </Link>
                <Link className="dropdown-item" to="/portfolio/all">
                  All
                </Link>
                <Link className="dropdown-item" to="/portfolio/ledger">
                  Ledger
                </Link>
                <Link className="dropdown-item" to="/portfolios">
                  Portfolios
                </Link>
                <Link className="dropdown-item" to="/watchlist">
                  Watchlist
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaBoxOpen />
                </strong>
                &nbsp; Structure
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/structure/autocallable/details">
                  AC Structures
                </Link>
                <Link className="dropdown-item" to="/structure/autocallable/expired/details">
                  AC Expired Structures
                </Link>
                <Link className="dropdown-item" to="/structure/autocallable/curated/details">
                  Curated AC Structures
                </Link>
                <Link className="dropdown-item" to="/structure/dispersion-warrent/details">
                  DW Structures
                </Link>
                <Link className="dropdown-item" to="/structure/dispersion-warrent/expired/details">
                  DW Expired Structures
                </Link>
                <Link className="dropdown-item" to="/structure/twin-win/details">
                  Twin Win Structures
                </Link>
                <Link className="dropdown-item" to="/structure/twin-win/expired/details">
                  Twin Win Expired Structures
                </Link>
                <Link className="dropdown-item" to="/structure/shark-note/details">
                  Shark Note Structures
                </Link>
                <Link className="dropdown-item" to="/structure/shark-note/expired/details">
                  Shark Note Expired Structures
                </Link>
                <Link className="dropdown-item" to="/structure/autocallable/autocall-status">
                  Autocall Status
                </Link>
                <Link className="dropdown-item" to="/structure/autocallable/matured-risk">
                  Matured Risk
                </Link>
                <Link className="dropdown-item" to="/structure/autocallable/risk-assesment">
                  Risk Assesment
                </Link>
                <Link className="dropdown-item" to="/structure/autocallable/underlying">
                  Structure Underlying
                </Link>
                <Link className="dropdown-item" to="/structure/matrix">
                  Structure Matrix
                </Link>
                <Link className="dropdown-item" to="/structure/coupon-cashflow">
                  Coupon Cashflow
                </Link>
                <Link className="dropdown-item" to="/structure/analysis">
                  Structure Analysis
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaLightbulb />
                </strong>
                &nbsp; Ideas
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/structure/autocallable/ideas">
                  AC Structure Ideas
                </Link>
                <Link className="dropdown-item" to="/structure/dispersion-warrent/ideas">
                  DW Structure Ideas
                </Link>
                <Link className="dropdown-item" to="/structure/twin-win/ideas">
                  Twin Win Structure Ideas
                </Link>
                <Link className="dropdown-item" to="/structure/shark-note/ideas">
                  Shark Note Structure Ideas
                </Link>
                <Link className="dropdown-item" to="/product/equity">
                  Equity Ideas
                </Link>
                <Link className="dropdown-item" to="/product/bond">
                  Bond Ideas
                </Link>
                <Link className="dropdown-item" to="/product/fund">
                  Fund Ideas
                </Link>
                <Link className="dropdown-item" to="/product/etf">
                  ETF Ideas
                </Link>
                <Link className="dropdown-item" to="/product/index">
                  Index Ideas
                </Link>
                <Link className="dropdown-item" to="/product/indices">
                  All Indices
                </Link>
                <Link className="dropdown-item" to="/product/bonds">
                  External Bonds
                </Link>
                <Link className="dropdown-item" to="/product/index-components?code=GSPC%20INDEX">
                  S&P 500 Weightage
                </Link>
                <Link
                  className="dropdown-item"
                  to="/product/index-components-pivot?code=GSPC%20INDEX">
                  S&P 500 Pivot
                </Link>
                <Link className="dropdown-item" to="/product/index-components?code=FTSE%20INDEX">
                  FTSE Weightage
                </Link>
                <Link
                  className="dropdown-item"
                  to="/product/index-components-pivot?code=FTSE%20INDEX">
                  FTSE Pivot
                </Link>
                <Link className="dropdown-item" to="/product/etfs">
                  ETF Lists
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaChartBar />
                </strong>
                &nbsp; Options
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/options/option-chain">
                  Option Chain
                </Link>
                <Link className="dropdown-item" to="/options/option-matrix">
                  Option Matrix
                </Link>
                <Link className="dropdown-item" to="/options/surface-graphs">
                  Surface Graph
                </Link>
                <Link className="dropdown-item" to="/options/strategy">
                  Options Strategy
                </Link>
                <Link className="dropdown-item" to="/options/put-writing-strategy">
                  Calculate PWS
                </Link>
                <Link className="dropdown-item" to="/options/put-writing-strategies/codes">
                  Put Writing Strategy trades
                </Link>
                <Link className="dropdown-item" to="/options/call-writing-strategy">
                  Calculate CWS
                </Link>
                <Link className="dropdown-item" to="/options/call-writing-strategies/codes">
                  Call Writing Strategy trades
                </Link>
                <Link
                  className="dropdown-item"
                  to="/options/put-writing-strategies/index?code=GSPC%20INDEX">
                  Put Writing Strategy S&P
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaFilter />
                </strong>
                &nbsp; Screener
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/screener/email">
                  Email
                </Link>
                <Link className="dropdown-item" to="/screener/equity">
                  Equity
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown">
                <strong>
                  <FaBell />
                </strong>
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/screener/alert">
                  Alerts
                </Link>
              </div>
            </li>
            {(this.state.userType === 'Admin' || this.state.userType === 'BackOffice') && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbardrop"
                  data-toggle="dropdown">
                  <strong>
                    <FaPersonBooth />
                  </strong>
                  &nbsp; Admin
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/structure/autocallable/add">
                    Create Autocallable Structure
                  </Link>
                  <Link className="dropdown-item" to="/structure/dispersion-warrent/add">
                    Create Dispersion Warrent Structure
                  </Link>
                  <Link className="dropdown-item" to="/structure/twin-win/add">
                    Create Twin Win Structure
                  </Link>
                  <Link className="dropdown-item" to="/structure/shark-note/add">
                    Create Shark Note Structure
                  </Link>
                  <Link className="dropdown-item" to="/options/analyse">
                    Create Option Sheet
                  </Link>
                  <Link className="dropdown-item" to="/product/add-instrument">
                    Create Instrument
                  </Link>
                  <Link className="dropdown-item" to="/relationship-management/link-user">
                    RM Add Client
                  </Link>
                  <Link className="dropdown-item" to="/portfolio/portfolio-Construction">
                    Portfolio Construction
                  </Link>
                  <Link className="dropdown-item" to="/add-user">
                    Add User
                  </Link>
                  <Link className="dropdown-item" to="/users">
                    Users
                  </Link>
                  <Link className="dropdown-item" to="/link-portfolio">
                    Link Portfolio
                  </Link>
                  <Link className="dropdown-item" to="/portfolio/add">
                    Add Order
                  </Link>
                  <Link className="dropdown-item" to="/portfolio/asset-mapping">
                    Asset Mapping
                  </Link>
                  <Link className="dropdown-item" to="/quote/search">
                    Search Quote
                  </Link>
                  <Link className="dropdown-item" to="/structure/autocallable/term-sheet">
                    Term Sheet Upload
                  </Link>
                  <Link className="dropdown-item" to="/stock-split">
                    Stock Split
                  </Link>
                  <Link className="dropdown-item" to="/login-history">
                    Login History
                  </Link>
                  <Link className="dropdown-item" to="/add-login">
                    Create Login
                  </Link>
                  <Link className="dropdown-item" to="/upload/planetary-movements">
                    Upload Planetary Movement Sheet
                  </Link>
                  <Link className="dropdown-item" to="/upload/bond-price">
                    Upload Bond Price Sheet
                  </Link>
                  <Link className="dropdown-item" to="/planetary-movement">
                    Planetary Movement
                  </Link>
                </div>
              </li>
            )}

            {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                            <strong><FaChartBar/></strong> &nbsp;
                            Portfolio
                        </a>
                        <div className="dropdown-menu">
                            <Link className="dropdown-item" to="/portfolio-consolidation/add-purchase">Add Purchase</Link>
                            <Link className="dropdown-item" to="/portfolio-consolidation/upload-portfolio">Upload Portfolio</Link>
                            <Link className="dropdown-item" to="/portfolio-consolidation/asset-mapping">Asset Mapping</Link>
                            <Link className="dropdown-item" to="/portfolio-consolidation/view-portfolios">View Portfolios</Link>
                        </div>
                    </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/profile">
                Hi {this.state.user_name && this.state.user_name.split('@')[0]}
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-item btn btn-danger" onClick={this.logOutUser}>
                Logout
              </a>
            </li>
          </ul>
          {/* <form className="form-inline ml-auto">
                    <div className="input-group">
                        <input type="text" className="form-control border-dark" placeholder="Search"/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button"><i className="fa fa-search"></i></button>
                            </div>
                    </div>
                </form> */}
        </nav>
        {this.state.suggestions.length > 0 && (
          <div
            className="dropdown container container-fluid"
            style={{ position: 'relative', width: '100%', marginTop: '50px' }}>
            <ul
              className="list-group"
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                maxHeight: '200px',
                // overflowY: "auto", // Add this line to enable vertical scrolling
                maxWidth: '300px', // Adjust this value as needed
                zIndex: 999 // Add this line to set the z-index
              }}>
              {this.state.suggestions.map((value, index) => {
                return (
                  <a
                    className="list-group-item list-group-flush"
                    href={`/product/equity?code=${value.code}`}
                    onClick={this.selectedScrip}
                    id={value.code}
                    key={index}>
                    {value.code}
                    &nbsp; ({value.name}) &nbsp;
                    <img src={Flags[value.country_iso]}></img>
                  </a>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  }
}

export default Navbar;
